






































import { Component, Vue } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import { AccessApi } from "@/networks/Access";
import store from "../../../../store";

@Component({})
export default class Quick extends Vue {
  private form: Record<string, unknown> = {
    name: "",
    permissions: []
  };
  private errors: Record<string, any> = {};
  private allPermissions = [];
  private permissions: Array<number> = [];
  private isEdit = false;
  private key = 1;

  async mounted() {
    await this.permission();
    this.isEdit = !!this.$route.params.id;
    if (this.isEdit) {
      await this.rolePermission();
    }
  }

  public async permission(): Promise<void> {
    try {
      const res: AxiosResponse = await new AccessApi().getRoles();
      this.allPermissions = res.data.data.permissions;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  public async rolePermission(): Promise<void> {
    try {
      const res: AxiosResponse = await new AccessApi().editRole(
        this.$route.params.id
      );
      this.permissions = res.data.data.role_permissions_id;
      this.form.name = res.data.data.roles.name;

      const p = this.allPermissions;
      for (const i in p) {
        const item: Record<any, any> = p[i];
        if (this.permissions.includes(item.id)) {
          (this.form.permissions as any)[item.id] = true;
          console.log("test");
        } else {
          (this.form.permissions as any)[item.id] = false;
          console.log("false-test");
        }
      }

      this.key += this.key;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  public async submit(): Promise<void> {
    try {
      const form = this.form;
      Object.keys(this.form).forEach((key) => !form[key] && delete form[key]);
      const res: AxiosResponse = await new AccessApi().addRole(form);
      this.errors = {};
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
      this.$router.push({ name: "access" });
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }
}
